<template>
    <div class="slope">
        <router-view/>
        <Nav />
    </div>
</template>

<script>
import Nav from '@/components/nav.vue'
// import '../../authority/slope.js'
export default {
    components:{
        Nav:Nav
    },
    data(){
        return{}
    }
}
</script>

<style lang="scss">
@import '../../assets/slope/index.scss'
</style>>
